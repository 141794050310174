import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {ContactForm} from "./ContactForm";
import {Grid, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function ContactFormSlide({contactForm}) {
    const [topics, setTopics] = React.useState()

    React.useEffect(() => {
        fetch(`assets/data/topics.json`)
            .then((r) => r.json())
            .then((topics) => setTopics(topics))
    }, [])

    const handleClose = () => contactForm.setOpen(false);

    return (
        <Dialog
            open={contactForm.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth
            maxWidth="md"
        >
            <Grid container justifyContent="space-between">
                <DialogTitle>Kontaktiere uns</DialogTitle>
                <DialogActions>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogActions>
                <DialogContent>
                    <ContactForm topics={topics}/>
                </DialogContent>
            </Grid>
        </Dialog>
    );
}

export {ContactFormSlide}
