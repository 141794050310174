import {Instagram} from "./Instagram";
import {Grid, Typography} from "@mui/material";
import {MediaIcons} from "../../util/MediaIcons";
import * as React from "react";

function switchPicture() {
    const today = new Date()
    const d = today.getUTCDate()
    const m = today.getUTCMonth() + 1

    switch (m) {
        case 1 :
            if (d <= 15) return 'BG-Gloecklerlauf.png'
            else return 'BG-Tanzen.png'
        case 2 :
        case 3 :
            return 'BG-Tanzen.png'
        case 4:
            return 'BG-Maibaum.png'
        case 5:
            if (d <= 15) return 'BG-Maibaum.png'
            else return 'BG-Heckentheater.png'
        case 6:
        case 7:
            return 'BG-Heckentheater.png'
        case 8:
            if (d <= 15) return 'BG-Heckentheater.png'
            else return 'BG-Tanzen.png'
        case 9:
        case 10:
            return 'BG-Tanzen.png'
        case 11:
            if (d <= 24) return 'BG-Tanzen.png'
            else return 'BG-WildeJagd.png'

        case 12:
            if (d <= 15) return 'BG-WildeJagd.png'
            else return 'BG-Gloecklerlauf.png'
        default:
            return null
    }
}

function Current({contactForm}) {
    return (
        <Grid container item flexDirection='column' alignItems='center' padding='1rem'
              sx={{
                  paddingTop: {md: '20rem'},
                  paddingBottom: {xs: '4.5rem', md: '10rem'},
                  backgroundImage: `url(assets/img/${switchPicture()})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto'
              }}>
            <Grid item maxWidth='30rem' width='80%' paddingTop='1rem' paddingBottom='2.5rem'
                  component='img' src={'assets/img/logo/logo.svg'}
                  sx={{display: {xs: 'flex', md: 'none'}}}
            />
            <Instagram contactForm={contactForm}/>
            <Grid item textAlign='center' pl='1rem' pr='1rem' mt='.5rem' mb='1rem' children={
                <Typography variant='body1' children='Folge uns auf Social Media, um nichts zu verpassen!'
                            sx={{textAlign: 'center'}}/>
            }/>
            <MediaIcons/>
            <video width="100%" src={'assets/video/JungalpenlandKURZFASSUNG.mp4'} controls
                   controlsList="nodownload noplaybackrate"
                   style={{
                       maxWidth: '85rem',
                       borderRadius: '1rem'
                   }}/>
        </Grid>
    )
}

export {Current}
