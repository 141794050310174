import {Current} from "./current/Current";
import {Board} from "./board/Board";
import {Grid} from "@mui/material";
import * as React from "react";
import {Aktivitaeten} from "./aktivitaeten/Aktivitaeten";
import {Brauchtum} from "./brauchtum/Brauchtum";

function Home({contactForm}) {
    return (
        <Grid container item maxWidth='120rem' flexDirection='column' alignItems='center'>
            <Current contactForm={contactForm}/>
            <Aktivitaeten/>
            <Brauchtum/>
            <Board/>
        </Grid>
    )
}

export {Home}
