import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import {Grid, ListItemButton, Paper} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";

function NavBookmark({children, onClick}) {
    return (
        <Grid item container justifyContent='center' alignContent='center'
              component={Paper} elevation={3} onClick={onClick}
              width='2.5rem' height='2.5rem' borderRadius='.8rem 0rem 0rem .8rem'
              sx={{
                  display: {xs: 'flex', md: 'none'},
                  backgroundColor: '#164673',
                  "&&:hover": {backgroundColor: '#2277BD'}
              }}>
            {children}
        </Grid>
    )
}

function NavItem({item, sub}) {
    return (
        <>
            <ListItemButton component="a" href={item.path}>
                <ListItemIcon>
                    {item.icon}
                </ListItemIcon>
                <ListItemText disableTypography={sub} primary={item.title}
                              style={{fontSize: {sub} ? '.9rem' : ''}}/>
            </ListItemButton>
            {item?.children && <Divider/>}
            {
                item?.children?.map((child, i) =>
                    <NavItem item={child} sub key={i}/>
                )
            }
        </>
    )
}

function NavMobile({items, contactForm}) {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (open) => () => setOpen(open);

    return (
        <div style={{
            position: 'fixed',
            right: '0',
            top: '0',
            zIndex: "1",
            paddingTop: '.5rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.5rem'
        }}>
            <NavBookmark onClick={toggleDrawer(true)}>
                <MenuIcon sx={{fontSize: '2rem', color: 'white'}}/>
            </NavBookmark>
            <NavBookmark onClick={() => contactForm.setOpen(true)}>
                <MessageIcon sx={{fontSize: '1.6rem', color: 'white'}}/>
            </NavBookmark>
            <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                <Box onClick={toggleDrawer(false)}>
                    <List>
                        {items.map((item, i) =>
                            <NavItem item={item} key={i}/>
                        )}
                    </List>
                </Box>
            </Drawer>
        </div>
    )
}

export {NavMobile, NavBookmark}
