import {Button, Grid, Paper} from "@mui/material";
import * as React from "react";
import MessageIcon from "@mui/icons-material/Message";

function NavPaper({sx, children}) {
    return (
        <Grid item
              container justifyContent="space-between" alignContent="center"
              component={Paper} elevation={16}
              sx={{
                  height: "3.3rem",
                  mt: "1rem",
                  borderRadius: "1rem",
                  ...sx,
                  display: {xs: 'none', md: 'flex'}
              }}>
            {children}
        </Grid>
    )
}

function NavItem({item}) {
    if (!item.title || !item.path) return null;

    return (
        <Grid item flexBasis='content' xs={true} component={"a"} href={item.path}
              style={{textDecoration: "none", width: "100%", height: "100%"}}>
            <Button variant="text" fullWidth sx={{
                height: "100%",
                color: "black",
                textTransform: "capitalize",
                fontWeight: 'bold',
                fontSize: "1rem",
                borderTopLeftRadius: item.left ? "1rem" : "0rem",
                borderBottomLeftRadius: item.left ? "1rem" : "0rem",
                borderTopRightRadius: item.right ? "1rem" : "0rem",
                borderBottomRightRadius: item.right ? "1rem" : "0rem",
                "&&:hover": {
                    color: '#2277BD',
                    backgroundColor: 'transparent'
                }
            }}>{item.title}</Button>
        </Grid>
    );
}

function NavDesktop({items, contactForm}) {
    return (
        <Grid item maxWidth="118rem" position="fixed" zIndex="1"
              container justifyContent='space-around'
              sx={{display: {xs: 'none', md: 'flex', pointerEvents: 'none'}}}>
            <NavPaper sx={{
                height: "6rem",
                width: "16rem",
                mt: '0rem',
                borderTopLeftRadius: "0rem",
                borderTopRightRadius: "0rem",
                pointerEvents: 'auto',
            }}>
                <a href='/#' style={{
                    width: '100%',
                    height: '100%',
                    borderBottomLeftRadius: '1rem',
                    borderBottomRightRadius: '1rem'
                }}>
                    <div style={{
                        width: '90%', height: '90%', margin: '5%',
                        backgroundImage: `url(assets/img/logo/logo.svg)`, backgroundRepeat: 'no-repeat'
                    }}></div>
                </a>
            </NavPaper>
            <Grid item container justifyContent="space-between" width='38rem'>
                <NavPaper sx={{
                    width: '33rem',
                    pointerEvents: 'auto',
                }}>
                    {items.map((item, i) =>
                        <NavItem item={item} key={i}/>
                    )}
                </NavPaper>
                <NavPaper sx={{
                    width: '4rem',
                    pointerEvents: 'auto',
                }}>
                    <Button variant="text" onClick={() => contactForm.setOpen(true)} fullWidth
                            sx={{
                                height: '100%',
                                color: "#152030",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                borderRadius: "1rem",
                                "&&:hover": {
                                    color: '#2277BD',
                                    backgroundColor: 'transparent'
                                }
                            }}>
                        <MessageIcon sx={{fontSize: '1.6rem'}}/>
                    </Button>
                </NavPaper>
            </Grid>
        </Grid>
    )
}

export {NavDesktop}
