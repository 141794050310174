import {Grid} from "@mui/material";
import * as React from "react";
import {TimelineMobile} from "./TimelineMobile";
import {TimelineDesktop} from "./TimelineDesktop";
import {Title} from "../../util/Font";

function Brauchtum() {
    const [data, setData] = React.useState()

    React.useEffect(() => {
        fetch(`assets/data/chapter_brauchtum.json`)
            .then((r) => r.json())
            .then((data) => setData(data))
    }, [])

    return (
        <Grid container item flexDirection='column' alignItems='center' padding='1rem'
              sx={{
                  paddingBottom: {xs: '4.5rem', md: '10rem'},
                  backgroundImage: 'url(assets/img/CHAPTER-Brauchtum.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto'
              }}>
            <Grid item id='brauchtum' sx={{
                paddingTop: {xs: '4.5rem', md: '11rem'},
                minWidth: {xs: '40%', md: '30%', lg: '16%'},
                textAlign: 'center'
            }} children={
                <Title children='Brauchtum'/>}/>
            <TimelineDesktop brauchtuemer={data?.brauchtuemer}/>
            <TimelineMobile brauchtuemer={data?.brauchtuemer}/>
        </Grid>
    )
}

export {Brauchtum}
