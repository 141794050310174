import {Grid} from "@mui/material";
import * as React from "react";
import {Absatz, CardText, Subtitle, Title} from "./util/Font";
import {Helmet} from "react-helmet";

function DSVGO() {
    const [text, setText] = React.useState()
    React.useEffect(() => {
        fetch(`assets/data/dsgvo.json`)
            .then((r) => r.json())
            .then((text) => setText(text))
    }, [])

    return (
        <Grid container item maxWidth='120rem' flexDirection='column' flexGrow={1} alignItems='center' padding='1rem'
              paddingBottom='5rem'>
            <Helmet>
                <title>Jung Alpenland - Datenschutzerklärung</title>
            </Helmet>
            <Grid item maxWidth='20rem' width='70%' paddingTop='1rem'
                  component='img' src={'assets/img/logo/logo.svg'}
                  sx={{display: {xs: 'flex', md: 'none'}}}
            />
            <Grid item maxWidth='50rem' sx={{paddingTop: {xs: '2rem', md: '6.5rem'}}} textAlign='center'>
                <Title children={text?.title}/>
                {text?.text.map((line, i) =>
                    typeof (line) === typeof ([])
                        ? <React.Fragment key={i}>
                            <br/>
                            {line.map((line, j) =>
                                <Absatz key={j} children={line}
                                        sx={{paddingTop: '0', fontWeight: j === 0 ? 'bold' : ''}}/>)}
                        </React.Fragment>
                        : <Absatz key={i} children={line}/>
                )}

                {text?.paragraphs?.map((paragraph, i) =>
                    <React.Fragment key={i}>
                        <Subtitle children={paragraph?.title}/>
                        {paragraph?.subtitle &&
                            <Absatz children={paragraph.subtitle} sx={{color: "#2277BD"}}/>}
                        {paragraph?.text.map((line, j) =>
                            typeof (line) === typeof ([])
                                ? <ul key={j} style={{textAlign: "left"}}>
                                    {line.map((line, k) =>
                                        <li key={k}><CardText children={line}/></li>)}
                                </ul>
                                : <Absatz key={j} children={line}/>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    )
}

export {DSVGO}
