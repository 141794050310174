import {Grid, Paper, Skeleton} from "@mui/material";
import * as React from "react";
import {CardLink, CardTitle} from "../../util/Font";
import {TimelineCardText} from "./TimelineCardText";

function TimelineDesktopItem({brauchtum, sx, left}) {
    if (!brauchtum) return null

    return (
        <Grid container item flexDirection='column' component={Paper}
              sx={{
                  ...sx,
                  maxWidth: '100%',
                  filter: 'drop-shadow(0 0 .5rem lightgrey)',
                  padding: {md: '.35em', lg: '.35rem'}
              }}>
            <Grid item children>
                {brauchtum.img
                    ? <img src={`assets/img/${brauchtum.img}`} alt={brauchtum.title} width='100%'
                           style={{borderRadius: '4px'}}/>
                    : <Skeleton variant='rectangular' width='100%' height='10rem' animation={false}/>}
            </Grid>
            <Grid item>
                <CardTitle children={brauchtum.title}
                           sx={{
                               paddingTop: {md: '1rem', lg: '1.75rem'},
                               paddingBottom: {md: '1rem', lg: '.75rem'},
                               textAlign: 'center'
                           }}/>
            </Grid>
            <TimelineCardText brauchtum={brauchtum}/>
            <Grid item alignSelf='center' sx={{paddingY: {md: '.5rem', lg: '1rem'}}} children={
                <a href={brauchtum.href} style={{textDecoration: 'none'}}>
                    <CardLink children='Mehr lesen'/>
                </a>
            }/>
            <div style={{
                position: 'absolute',
                transform: left ? 'rotate(90deg)' : 'rotate(-90deg)',
                right: left ? '-2rem' : '',
                left: left ? '' : '-2rem',
                top: '4.5rem',
                width: '0rem',
                height: '0rem',
                borderLeft: '1.5rem solid transparent',
                borderRight: '1.5rem solid transparent',
                borderBottom: '1.5rem solid white'
            }}/>
        </Grid>
    )
}

export {TimelineDesktopItem}
