import * as React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Button, Grid, Paper} from "@mui/material";
import {CardLink, CardTitle} from "../../util/Font";
import {TimelineCardText} from "./TimelineCardText";

function TimelineMobile({brauchtuemer}) {
    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => () => setExpanded(panel);

    React.useEffect(() => {
        if (brauchtuemer) setExpanded(brauchtuemer[0].title)
    }, [brauchtuemer])

    if (!brauchtuemer) {
        return null
    }

    return (
        <Grid item maxWidth='35rem' marginTop='2rem'
              sx={{display: {md: 'none'}}}
              component={Paper} elevation={3}>
            {brauchtuemer.map((brauchtum, i) =>
                <MuiAccordion key={i} expanded={expanded === brauchtum.title}
                              onChange={handleChange(brauchtum.title)}
                              sx={{boxShadow: '0 0 0 0', '&::before': {marginLeft: '5%', marginRight: '5%'}}}>
                    <MuiAccordionSummary
                        sx={{"&& > *": {margin: '0'}, padding: '0rem', paddingX: '.35rem', paddingTop: '.35rem'}}>
                        {brauchtum.img && expanded === brauchtum.title &&
                            <img src={`assets/img/${brauchtum.img}`} alt={brauchtum.title} width='100%'
                                 style={{borderRadius: '4px'}}/>}
                        {brauchtum.img && expanded !== brauchtum.title &&
                            <Button fullWidth
                                    sx={{
                                        height: '100%',
                                        padding: '0rem',
                                        pt: '.5rem',
                                        pb: '.5rem',
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        fontSize: expanded === brauchtum.title ? '1rem' : '',
                                        color: expanded === brauchtum.title ? 'black' : '#808080',
                                        "&&:hover": {color: expanded === brauchtum.title ? 'black' : '#2277BD'}
                                    }}>
                                {brauchtum.title}
                            </Button>
                        }
                    </MuiAccordionSummary>
                    <MuiAccordionDetails sx={{padding: '0'}}>
                        <Grid container flexDirection='column' alignItems='center'>
                            <CardTitle children={brauchtum.title}
                                       sx={{
                                           paddingTop: {xs: '1rem', sm: '1.75rem'},
                                           paddingBottom: {xs: '.75rem', sm: '1rem'},
                                           textAlign: 'center'
                                       }}/>
                            <TimelineCardText brauchtum={brauchtum}/>
                            <Grid item sx={{paddingY: '1rem'}} children={
                                <a href={brauchtum.href} style={{textDecoration: 'none'}}>
                                    <CardLink children='Mehr lesen'/>
                                </a>
                            }/>
                        </Grid>
                    </MuiAccordionDetails>
                </MuiAccordion>
            )}
        </Grid>
    );
}

export {TimelineMobile}
