import {Typography} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";

const pattern = /<sup>\d<\/sup>+/g

export const Title = ({children, sx}) => {
    if (!children) {
        return <></>
    }

    let sup = children.match(pattern)
    let text = children.replace(pattern, '').trim()

    return <Typography variant='h1'
                       sx={{
                           fontSize: {xs: '1.4rem', sm: '1.8rem'},
                           paddingBottom: {xs: '.5rem', sm: '.7rem', md: '.85rem'},
                           letterSpacing: '.05rem'
                           , ...sx
                       }}>
        {text}
        {sup &&
            <sup style={{fontSize: '.8rem'}}>{sup[0][5]}</sup>
        }
    </Typography>
}


export const Subtitle = ({children, sx}) => {
    if (!children) {
        return <></>
    }

    let sup = children.match(pattern)
    let text = children.replace(pattern, '').trim()

    return <Typography variant='h2' fontWeight='bold'
                       sx={{
                           fontSize: {xs: '1.1rem', sm: '1.22rem'},
                           paddingTop: {xs: '2.5rem', sm: '3rem', md: '4rem'}, ...sx
                       }}>
        {text}
        {sup &&
            <sup style={{fontSize: '.8rem', fontWeight: 'normal'}}>{sup[0][5]}</sup>
        }
    </Typography>
}


export const FooterTitle = ({children, sx}) =>
    <Typography variant='h6' color='white' fontWeight='bold' children={children} sx={{...sx}}/>

export const FooterLink = ({children, sx}) =>
    <Typography variant='body1' color='#2277BD' fontSize='.8rem' children={children}
                sx={{"&&:hover": {color: '#77B5FE'}, ...sx}}/>

export const FooterText = ({children, sx}) =>
    <Typography variant='body1' color='white' fontSize='.8rem' noWrap children={children} sx={{...sx}}/>

export const Absatz = ({children, sx}) =>
    <CardText sx={{textAlign: 'justify', paddingTop: '1rem', ...sx}} children={children}/>

export const CardLink = ({children, sx, onClick}) =>
    <Typography variant='h6' fontSize='.8rem' color='#2277BD' children={children}
                sx={{"&&:hover": {color: '#164673'}, ...sx}} onClick={onClick}/>

export const CardText = ({children, sx}) =>
    <Typography variant='body2' lineHeight='1.5rem' children={children} sx={{...sx}}/>

export const CardTitle = ({children, sx}) =>
    <Typography variant='h6' fontWeight='bold' fontSize='1.2rem' children={children} sx={{...sx}}/>

export const Poem = ({children, sx}) =>
    <Typography variant='body2' fontStyle="italic" color='#454545' children={children} sx={{...sx}}/>

export const MemberTitle = ({children, sx}) =>
    <Typography variant='h6' fontWeight='bold' children={children}
                mb='-.5rem' mt='.5rem' width='100%' textAlign='center'
                sx={{fontSize: {xs: '.8rem', sm: '.95rem'}, ...sx}}/>

export const MemberName = ({children, sx}) =>
    <Typography variant='body1' children={children} width='100%' textAlign='center'
                sx={{fontSize: {xs: '.7rem', sm: '.8rem'}, ...sx}}/>

export const SourceText = ({children, sx}) => {
    if (!children) {
        return <></>
    }
    return <>
        <Divider sx={{paddingTop: '2rem'}}/>
        {children.map((child, i) => {

            let sup = child.match(pattern)
            let text = child.replace(pattern, '').trim()

            return <CardText key={i}
                             sx={{fontSize: '.8rem', textAlign: 'left', ...sx}}>
                {sup &&
                    <sup style={{fontSize: '.6rem', fontWeight: 'normal'}}>{sup[0][5]}</sup>
                }
                {text}</CardText>
        })}
    </>
}

