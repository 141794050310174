import {Grid} from "@mui/material";
import React from "react";
import {Member} from "./Member";
import {Absatz, Title} from "../../util/Font";

function Board() {
    const [board, setBoard] = React.useState()

    React.useEffect(() => {
        fetch(`assets/data/board.json`)
            .then((r) => r.json())
            .then((board) => setBoard(board))
    }, [])

    return (
        <Grid container item justifyContent='center'
              sx={{
                  padding: '1rem',
                  paddingBottom: {xs: '4.5rem', md: '10rem'},
                  paddingLeft: {xs: '0rem', sm: '1rem'},
                  paddingRight: {xs: '0rem', sm: '1rem'},
                  maxWidth: {xs: '50rem', md: '100%'},
                  backgroundImage: 'url(assets/img/CHAPTER-Vorstand.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto'
              }}>
            <Grid item id='vorstand' sx={{
                paddingTop: {xs: '4.5rem', md: '11rem'},
                minWidth: {xs: '40%', md: '30%', lg: '16%'},
                textAlign: 'center'
            }} mb='2.5rem' children={
                <Title children='Vorstand'/>
            }/>
            {board?.members?.map((members, i) =>
                <Grid container item justifyContent='center' mb='1rem' key={i}>
                    {members.map((member, i) =>
                        <Member member={member} key={i}/>)}
                </Grid>)}
            <Grid item textAlign='center' pl='1rem' pr='1rem' children={
                <Absatz>gewählt seit {board?.since}</Absatz>
            }/>
        </Grid>
    )
}

export {Board}
