import {Avatar, Grid} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from '@mui/icons-material/YouTube';
import MessageIcon from "@mui/icons-material/Message";

const MediaAvatar = (props) =>
    <a href={props.href} target="_blank" rel="noreferrer">
        <Avatar
            children={props.children}
            onClick={props.onClick}
            sx={{
                width: '2.6rem',
                height: '2.6rem',
                cursor: 'pointer',
                backgroundColor: props.variant === 'footer' ? '#164673' : '#152030',
                "&&:hover": {
                    backgroundColor: props.variant === 'footer' ? '#2277BD' : '#164673'
                }
            }}
        />
    </a>

function MediaIcons({variant, contactForm, sx}) {
    return (
        <Grid container item spacing={1} mb="2rem" justifyContent={variant === 'footer' ? '' : 'center'} sx={{...sx}}>
            <Grid item children={
                <MediaAvatar variant={variant} href="https://www.instagram.com/jungalpenland/" children={
                    <InstagramIcon sx={{fontSize: '2rem'}}/>
                }/>
            }/>
            <Grid item children={
                <MediaAvatar variant={variant} href="https://www.facebook.com/jungalpenland/" children={
                    <FacebookOutlinedIcon sx={{fontSize: '2rem'}}/>
                }/>
            }/>
            <Grid item children={
                <MediaAvatar variant={variant} href="https://www.youtube.com/channel/UCJfMhssLM8E-EdapE7ZeraQ/featured"
                             children={
                                 <YouTubeIcon sx={{fontSize: '2rem'}}/>
                             }/>
            }/>
            {variant === 'footer' &&
                <Grid item children={
                    <MediaAvatar onClick={() => contactForm.setOpen(true)} variant={variant}>
                        <MessageIcon sx={{fontSize: '1.6rem'}}/>
                    </MediaAvatar>
                }/>
            }

        </Grid>
    )
}

export {MediaIcons, MediaAvatar}
