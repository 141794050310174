import {TimelineDesktopItem} from "./TimelineDesktopItem";
import {Grid} from "@mui/material";
import * as React from "react";

function TimelineDesktop({brauchtuemer}) {
    if (!brauchtuemer) return null

    return (
        <Grid item container flexDirection='row' alignItems='flex-start' flexWrap='nowrap' maxWidth='90rem' mt='1.5rem'
              sx={{
                  display: {xs: 'none', md: 'flex'},
                  columnGap: {md: '2rem', lg: '3rem'}
              }}>
            <Grid item container marginTop='5rem' sx={{rowGap: {md: '4rem', lg: '6rem'}}}>
                <TimelineDesktopItem brauchtum={brauchtuemer[1]} left/>
                <TimelineDesktopItem brauchtum={brauchtuemer[3]} left/>
            </Grid>
            <Grid item width='2rem'
                  style={{
                      boxShadow: '.5rem 0rem .5rem -0.3rem lightgrey, -.5rem 0rem .5rem -0.3rem lightgrey',
                      backgroundColor: 'white',
                      borderRadius: '.25rem',
                      alignSelf: "stretch"
                  }}/>
            <Grid item container rowGap='3rem'>
                <TimelineDesktopItem brauchtum={brauchtuemer[0]}/>
                <TimelineDesktopItem brauchtum={brauchtuemer[2]}/>
            </Grid>
        </Grid>
    )
}

export {TimelineDesktop}
