import {Grid} from "@mui/material";
import * as React from "react";
import {Tabs} from "./Tabs";
import {Aktivitaet} from "./Aktivitaet";
import {Title} from "../../util/Font";

function Aktivitaeten() {
    const [data, setData] = React.useState()
    const [selection, setSelection] = React.useState(0);

    React.useEffect(() => {
        fetch(`assets/data/chapter_aktivitaeten.json`)
            .then((r) => r.json())
            .then((data) => setData(data))
    }, [])

    const changeSelection = (selection) => () => setSelection(selection);

    return (
        <Grid container item flexDirection='column' alignItems='center' padding='1rem'
              sx={{
                  paddingBottom: {xs: '4.5rem', md: '10rem'},
                  backgroundImage: 'url(assets/img/CHAPTER-Aktivitaeten.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto'
              }}>
            <Grid item id='aktivitaeten'
                  sx={{
                      paddingTop: {xs: '4.5rem', md: '11rem'},
                      minWidth: {xs: '40%', md: '30%', lg: '16%'},
                      textAlign: 'center'
                  }} children={
                <Title children='Aktivitäten'/>}/>
            <Grid item container flexWrap='nowrap'
                  sx={{
                      flexDirection: {xs: 'column', md: 'row'},
                      justifyContent: {md: 'center'},
                      alignItems: 'flex-start',
                      gap: {md: '1rem', lg: '3rem'},
                      display: {xs: 'none', md: 'flex'}
                  }}
            >
                {data?.aktivitaeten.map((item, i) =>
                    <Aktivitaet aktivitaet={item} key={i}/>
                )}
            </Grid>
            <Grid item container justifyContent='center'
                  sx={{display: {xs: 'flex', md: 'none'}}}>
                <Aktivitaet aktivitaet={data?.aktivitaeten[selection]}>
                    <Tabs selection={selection} changeSelection={changeSelection} aktivitaeten={data?.aktivitaeten}/>
                </Aktivitaet>
            </Grid>
        </Grid>
    )
}

export {Aktivitaeten}
