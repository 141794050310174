export default function Map() {
    return (
        <svg id="Solids" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.44 308.87"
             style={{fill: '#fff', maxWidth: '100%', maxHeight: '100%'}}>
            <title>Brauchtumsgruppe Jung Alpenland</title>
            <path className="cls-1"
                  d="M168.68,195.78l8.56-8.65,4.86,4.81-1.45,1.46-3.44-3.4-2,2.05,3,2.94-1.45,1.47-3-3-3.64,3.68Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M178.08,205.08l.93-.93a3.07,3.07,0,0,1-1.53.06,2.52,2.52,0,0,1-1.25-.66,2.23,2.23,0,0,1-.49-2.51,5.41,5.41,0,0,1,1.21-1.62l3.93-4,1.36,1.35-2.85,2.88a16,16,0,0,0-1.49,1.63,1.19,1.19,0,0,0-.24.78,1.07,1.07,0,0,0,.33.69,1.4,1.4,0,0,0,.87.39,1.59,1.59,0,0,0,1-.24,13.22,13.22,0,0,0,1.72-1.56l2.62-2.64,1.36,1.34-6.2,6.27Zm4.6-10,1.41-1.42,1.17,1.16-1.41,1.42Zm2.08,2.06,1.41-1.42,1.17,1.16-1.41,1.42Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M182.05,205.49l1.61,1.09a1.45,1.45,0,0,0,.13,2.07,1.65,1.65,0,0,0,.91.54.85.85,0,0,0,.73-.25.67.67,0,0,0,.22-.43,1.45,1.45,0,0,0-.24-.65,8.17,8.17,0,0,1-1.29-3,2.46,2.46,0,0,1,.79-1.94,2.6,2.6,0,0,1,2-.85,3.16,3.16,0,0,1,2.13,1.07,3.54,3.54,0,0,1,1.14,1.91,3,3,0,0,1-.51,2l-1.57-1a1.25,1.25,0,0,0-.22-1.71,1.8,1.8,0,0,0-.86-.56.63.63,0,0,0-.58.16.6.6,0,0,0-.15.54,7.86,7.86,0,0,0,.79,1.62,4.75,4.75,0,0,1,.78,2.31,2.51,2.51,0,0,1-.8,1.61,2.75,2.75,0,0,1-2.11.88,3.4,3.4,0,0,1-2.3-1.11,3.68,3.68,0,0,1-1.14-2.1A3.3,3.3,0,0,1,182.05,205.49Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M194.82,209.25l-1.3,1.33-.93-.92-2.5,2.53a6.42,6.42,0,0,0-.9,1,.46.46,0,0,0,.1.57,2.14,2.14,0,0,0,.7.38l-1.16,1.4a3.32,3.32,0,0,1-1.31-.81,1.84,1.84,0,0,1-.59-1.07,1.8,1.8,0,0,1,.29-1.13,11.34,11.34,0,0,1,1.3-1.48l2.71-2.73-.63-.62,1.31-1.32.62.62,1.24-1.25,2.32.38-2.19,2.22Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M194.53,217.43,195.6,219a3.79,3.79,0,0,1-2.23.57,3.13,3.13,0,0,1-1.85-.93,3,3,0,0,1-1-2.5,5.34,5.34,0,0,1,1.72-3.21,5.66,5.66,0,0,1,3.23-1.79,2.77,2.77,0,0,1,2.41.8,2.92,2.92,0,0,1,.92,2.53,6.27,6.27,0,0,1-2,3.47l-.19.18-3.4-3.37a2.59,2.59,0,0,0-.81,1.49,1.37,1.37,0,0,0,.37,1.18Q193.42,218.1,194.53,217.43Zm1.73-1.62a2.59,2.59,0,0,0,.78-1.41,1.21,1.21,0,0,0-.33-1.06,1.25,1.25,0,0,0-1.11-.34,2.38,2.38,0,0,0-1.37.8Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M199.75,226.54l-1.36-1.35,3.17-3.2a9.78,9.78,0,0,0,1.19-1.36,1.19,1.19,0,0,0,.2-.77,1.09,1.09,0,0,0-.3-.65,1.43,1.43,0,0,0-.84-.39,1.69,1.69,0,0,0-1,.23,8.62,8.62,0,0,0-1.56,1.36l-2.82,2.84-1.36-1.35,6.2-6.26,1.26,1.24-.9.92a3.57,3.57,0,0,1,1.55-.05,2.39,2.39,0,0,1,1.19.66,2.29,2.29,0,0,1,.7,1.31,2.21,2.21,0,0,1-.22,1.36,7.19,7.19,0,0,1-1.24,1.56Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M206.47,224.55l-1-1.49a3.4,3.4,0,0,1,2.09-.64,3.32,3.32,0,0,1,1.9,1.06,4,4,0,0,1,1.07,1.54,1.93,1.93,0,0,1,0,1.3,5.54,5.54,0,0,1-1.3,1.71L207.33,230a12,12,0,0,0-1.14,1.28,5.32,5.32,0,0,0-.59,1.09L204.25,231l.5-.86a3.72,3.72,0,0,1-1.36-.12,2.41,2.41,0,0,1-1-.63,2.24,2.24,0,0,1-.73-1.72,2.62,2.62,0,0,1,.85-1.81,2.92,2.92,0,0,1,1.11-.74,2.38,2.38,0,0,1,1.18-.09,5.27,5.27,0,0,1,1.55.7,9.05,9.05,0,0,0,1.71.9,2.78,2.78,0,0,0,.61-.76.84.84,0,0,0,0-.54,1.66,1.66,0,0,0-.45-.65,1.32,1.32,0,0,0-.74-.41A1.72,1.72,0,0,0,206.47,224.55Zm.48,3.15a10.22,10.22,0,0,1-1.06-.55,2.53,2.53,0,0,0-1.19-.45,1,1,0,0,0-.71.32,1.3,1.3,0,0,0-.38.82,1,1,0,0,0,.29.78,1.4,1.4,0,0,0,.77.37,1.44,1.44,0,0,0,.86-.08,4.2,4.2,0,0,0,1.09-.88Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1" d="M206.65,233.36l8.56-8.65,1.36,1.35L208,234.71Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1" d="M209.4,236.08l8.56-8.65,1.36,1.35-8.56,8.65Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M217.11,239.78l1.08,1.61A3.81,3.81,0,0,1,216,242a3,3,0,0,1-1.85-.93,3,3,0,0,1-1-2.5,5.36,5.36,0,0,1,1.73-3.21,5.6,5.6,0,0,1,3.23-1.79,2.76,2.76,0,0,1,2.4.81,2.84,2.84,0,0,1,.92,2.52,6.21,6.21,0,0,1-2,3.47l-.19.18-3.41-3.37a2.71,2.71,0,0,0-.81,1.49,1.36,1.36,0,0,0,.38,1.18C215.79,240.24,216.37,240.23,217.11,239.78Zm1.74-1.61a2.64,2.64,0,0,0,.78-1.42,1.23,1.23,0,0,0-1.45-1.4,2.47,2.47,0,0,0-1.37.8Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M222.62,245.23l1.08,1.61a3.81,3.81,0,0,1-2.23.57,3.36,3.36,0,0,1-2.86-3.43,5.31,5.31,0,0,1,1.73-3.2,5.54,5.54,0,0,1,3.23-1.79,2.76,2.76,0,0,1,2.4.8,2.86,2.86,0,0,1,.92,2.53,6.22,6.22,0,0,1-2,3.46l-.19.19-3.41-3.37a2.69,2.69,0,0,0-.81,1.48,1.35,1.35,0,0,0,.38,1.18C221.29,245.69,221.88,245.68,222.62,245.23Zm1.74-1.61a2.58,2.58,0,0,0,.78-1.42,1.22,1.22,0,0,0-1.45-1.39,2.39,2.39,0,0,0-1.37.79Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M301.24,142l11.43-4.17.71,1.94-6.16,6.82,7.63-2.78.68,1.86-11.44,4.16-.73-2,6-6.69-7.45,2.71Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M309,149.94a5.12,5.12,0,0,1,2.4-.28,3.92,3.92,0,0,1,2,.8,3.67,3.67,0,0,1,1.16,1.68,3.43,3.43,0,0,1-.32,3.18,5.15,5.15,0,0,1-2.66,2.06,5.47,5.47,0,0,1-2.5.3,4,4,0,0,1-2-.79,3.74,3.74,0,0,1-1.17-1.65,3.63,3.63,0,0,1,.22-3.07A5,5,0,0,1,309,149.94Zm.57,2a3.22,3.22,0,0,0-1.73,1.2,1.69,1.69,0,0,0-.2,1.48,1.61,1.61,0,0,0,1.09,1,3.26,3.26,0,0,0,2.11-.17,3.14,3.14,0,0,0,1.7-1.19,1.66,1.66,0,0,0,.21-1.48,1.61,1.61,0,0,0-1.09-1A3.18,3.18,0,0,0,309.52,151.93Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M310.09,166.89l-.66-1.91,4.26-1.47a8.3,8.3,0,0,0,1.66-.7,1.23,1.23,0,0,0,.52-.62,1.14,1.14,0,0,0,0-.75,1.43,1.43,0,0,0-.59-.76,1.69,1.69,0,0,0-1-.27,8.82,8.82,0,0,0-2,.52l-3.78,1.31-.66-1.91,8.33-2.88.61,1.77-1.21.42a3.56,3.56,0,0,1,1.42.69,2.67,2.67,0,0,1,.81,1.19,2.45,2.45,0,0,1,.07,1.55,2.19,2.19,0,0,1-.78,1.16,6.27,6.27,0,0,1-1.8.87Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M312.94,175.37l-.65-1.92,4.28-1.43a8.47,8.47,0,0,0,1.67-.68,1.23,1.23,0,0,0,.52-.62,1,1,0,0,0,0-.74,1.5,1.5,0,0,0-.58-.78,1.69,1.69,0,0,0-1-.27,8.61,8.61,0,0,0-2,.5l-3.79,1.27-.64-1.92,8.36-2.8.6,1.78-1.22.41a3.53,3.53,0,0,1,1.41.71,2.6,2.6,0,0,1,.8,1.19,2.47,2.47,0,0,1,.06,1.56,2.24,2.24,0,0,1-.8,1.15,6.6,6.6,0,0,1-1.8.85Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M323,177.88l-1.77.58-.42-1.31-3.39,1.1a7.19,7.19,0,0,0-1.25.47.47.47,0,0,0-.16.58,2.12,2.12,0,0,0,.46.69l-1.67.73a3.61,3.61,0,0,1-.82-1.4A2,2,0,0,1,314,178a1.67,1.67,0,0,1,.77-.9,9.42,9.42,0,0,1,1.82-.72l3.66-1.19-.28-.88,1.77-.58.28.88,1.67-.54,1.92,1.5-3,1Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M321.37,181.52l-.19-1.85a3.33,3.33,0,0,1,2.17.47,3.55,3.55,0,0,1,1.21,1.92,4.39,4.39,0,0,1,.26,2,2,2,0,0,1-.6,1.2,5.36,5.36,0,0,1-1.94.91l-2.61.8a11.72,11.72,0,0,0-1.6.6,6.06,6.06,0,0,0-1,.7l-.6-1.9.84-.54a3.71,3.71,0,0,1-1.16-.79,2.74,2.74,0,0,1-.66-1.11,2.47,2.47,0,0,1,.13-1.95,2.59,2.59,0,0,1,1.6-1.24,2.82,2.82,0,0,1,1.33-.12,2.44,2.44,0,0,1,1.09.51,5.75,5.75,0,0,1,1.07,1.43,9.7,9.7,0,0,0,1.13,1.69,2.88,2.88,0,0,0,.88-.39.79.79,0,0,0,.24-.5,1.76,1.76,0,0,0-.11-.83,1.34,1.34,0,0,0-.47-.75A1.71,1.71,0,0,0,321.37,181.52Zm-1,3.14a8.76,8.76,0,0,1-.69-1,3.11,3.11,0,0,0-.86-1,1,1,0,0,0-.79-.06,1.28,1.28,0,0,0-.72.56,1.1,1.1,0,0,0-.09.87,1.58,1.58,0,0,0,.52.73,1.45,1.45,0,0,0,.81.35,4,4,0,0,0,1.37-.26Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1" d="M317.47,189.68l11.65-3.51.58,1.93-11.65,3.51Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M322.54,197l.18,2a3.68,3.68,0,0,1-2.24-.66,3.31,3.31,0,0,1-1.19-1.81,3.24,3.24,0,0,1,.31-2.8,5.12,5.12,0,0,1,3.06-2,5.43,5.43,0,0,1,3.71.08,3,3,0,0,1,1.74,2,3.1,3.1,0,0,1-.4,2.78,6,6,0,0,1-3.43,2.09l-.26.07-1.39-4.85a2.51,2.51,0,0,0-1.42.92,1.48,1.48,0,0,0-.24,1.27C321.15,196.75,321.67,197.05,322.54,197Zm2.3-.55a2.51,2.51,0,0,0,1.37-.87,1.35,1.35,0,0,0,.22-1.15,1.33,1.33,0,0,0-.82-.88,2.3,2.3,0,0,0-1.6,0Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M321.34,203.24l-.52-2,8.52-2.25.48,1.8-1.21.33a3.73,3.73,0,0,1,1.35.53,1.42,1.42,0,0,1,.51.77,2.3,2.3,0,0,1-.09,1.39l-2.13-.09a1.56,1.56,0,0,0,.13-1,1,1,0,0,0-.47-.63,1.91,1.91,0,0,0-1.16-.18,19.68,19.68,0,0,0-2.78.6Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M323.25,210.7l11.92-2.46.42,2.07-4.69,1,.85,4.08,4.69-1,.43,2.08L325,218.93l-.43-2.07,5.21-1.08-.84-4.09-5.21,1.08Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M331.51,221.57l.16-1.86a3.32,3.32,0,0,1,2,.86,3.58,3.58,0,0,1,.85,2.11,4.51,4.51,0,0,1-.1,2,2.07,2.07,0,0,1-.81,1.07,5.39,5.39,0,0,1-2.08.54l-2.7.31a12.25,12.25,0,0,0-1.69.3,6.1,6.1,0,0,0-1.14.5l-.24-2,.93-.38a3.82,3.82,0,0,1-1-1,2.77,2.77,0,0,1-.44-1.21,2.45,2.45,0,0,1,.48-1.89,2.58,2.58,0,0,1,1.79-.94,2.87,2.87,0,0,1,1.33.13,2.33,2.33,0,0,1,1,.7,5.73,5.73,0,0,1,.79,1.6,9.48,9.48,0,0,0,.8,1.86,2.68,2.68,0,0,0,.94-.22.79.79,0,0,0,.33-.45,1.84,1.84,0,0,0,0-.84,1.34,1.34,0,0,0-.33-.81A1.68,1.68,0,0,0,331.51,221.57ZM330,224.48a12.23,12.23,0,0,1-.49-1.15,2.81,2.81,0,0,0-.66-1.15,1,1,0,0,0-.76-.2,1.19,1.19,0,0,0-.81.42,1,1,0,0,0-.25.83,1.69,1.69,0,0,0,1.11,1.31,4,4,0,0,0,1.4,0Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M326.54,233.79l1.31-.1a3.08,3.08,0,0,1-1.19-1,2.63,2.63,0,0,1-.51-1.39,2.44,2.44,0,0,1,.27-1.37,2.27,2.27,0,0,1,1-1,5.37,5.37,0,0,1,2-.44l5.57-.4.15,2-4,.29a15.08,15.08,0,0,0-2.19.26,1.2,1.2,0,0,0-.7.45,1.22,1.22,0,0,0-.2.78,1.44,1.44,0,0,0,.41.9,1.51,1.51,0,0,0,.9.5,11.17,11.17,0,0,0,2.32,0l3.71-.27.15,2-8.79.64Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M335.58,237.32l.06,1.87-1.3,0a3,3,0,0,1,1.13,1,2.47,2.47,0,0,1,.44,1.35,2.8,2.8,0,0,1-1.11,2.28,5.33,5.33,0,0,1-3.33,1A5.56,5.56,0,0,1,328,244a2.78,2.78,0,0,1-1.28-2.21,2.31,2.31,0,0,1,.25-1.13,3.83,3.83,0,0,1,1-1.13l-4.43.13-.06-2Zm-4.2,2.12a3.68,3.68,0,0,0-2.19.57,1.49,1.49,0,0,0-.67,1.25,1.37,1.37,0,0,0,.69,1.14,3.64,3.64,0,0,0,2.15.41,3.5,3.5,0,0,0,2.06-.54,1.4,1.4,0,0,0,.63-1.2,1.46,1.46,0,0,0-.68-1.2A3.22,3.22,0,0,0,331.38,239.44Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M335.78,250h-1.86v-1.37h-3.56a7.57,7.57,0,0,0-1.34.06.47.47,0,0,0-.33.49,2.24,2.24,0,0,0,.22.81l-1.81.17a3.4,3.4,0,0,1-.34-1.58,1.93,1.93,0,0,1,.35-1.23,1.65,1.65,0,0,1,1-.62,8.63,8.63,0,0,1,2-.12h3.85v-.93h1.86v.92h1.75l1.36,2h-3.11Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M329.48,250.67l.3,2a1.51,1.51,0,0,0-1.43,1.58,1.87,1.87,0,0,0,.24,1.1.8.8,0,0,0,.68.37.64.64,0,0,0,.46-.14,1.57,1.57,0,0,0,.3-.65,9.28,9.28,0,0,1,1.28-3.12,2.4,2.4,0,0,1,2-.79,2.52,2.52,0,0,1,2,.87,3.52,3.52,0,0,1,.68,2.41,3.88,3.88,0,0,1-.62,2.26,2.92,2.92,0,0,1-1.81,1l-.35-1.9a1.31,1.31,0,0,0,1.1-1.41,2.06,2.06,0,0,0-.18-1.06.58.58,0,0,0-1-.06,9.09,9.09,0,0,0-.65,1.78,5,5,0,0,1-1.15,2.26,2.38,2.38,0,0,1-1.72.55,2.69,2.69,0,0,1-2.08-1,3.66,3.66,0,0,1-.76-2.57,3.87,3.87,0,0,1,.75-2.39A3.27,3.27,0,0,1,329.48,250.67Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M335.31,263.34l-1.85-.09.07-1.37L330,261.7a8,8,0,0,0-1.34,0,.48.48,0,0,0-.35.48,2.29,2.29,0,0,0,.18.82l-1.82.08a3.49,3.49,0,0,1-.26-1.6,1.93,1.93,0,0,1,.41-1.21,1.67,1.67,0,0,1,1-.57,8.9,8.9,0,0,1,2,0l3.84.19.05-.92,1.86.09-.05.93,1.75.08,1.25,2.09-3.11-.16Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M326.29,266.17l.14-2,8.79.61-.12,1.86-1.26-.08a3.62,3.62,0,0,1,1.11.94,1.32,1.32,0,0,1,.23.89,2.28,2.28,0,0,1-.53,1.29l-2-.77a1.6,1.6,0,0,0,.44-.91,1.07,1.07,0,0,0-.24-.76,1.9,1.9,0,0,0-1-.53,19.27,19.27,0,0,0-2.83-.34Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M332,271.92l.54-1.78a3.38,3.38,0,0,1,1.82,1.27,3.61,3.61,0,0,1,.38,2.24,4.38,4.38,0,0,1-.52,1.91,2.12,2.12,0,0,1-1,.88,5.62,5.62,0,0,1-2.15.09l-2.71-.27a11,11,0,0,0-1.71-.06,5.3,5.3,0,0,0-1.22.25l.18-2,1-.17a3.92,3.92,0,0,1-.77-1.18,2.66,2.66,0,0,1-.17-1.28,2.45,2.45,0,0,1,.87-1.75,2.6,2.6,0,0,1,1.95-.53,2.77,2.77,0,0,1,1.27.41,2.24,2.24,0,0,1,.81.89,5.46,5.46,0,0,1,.44,1.73,9,9,0,0,0,.39,2,2.55,2.55,0,0,0,1,0,.87.87,0,0,0,.41-.36,2,2,0,0,0,.22-.81,1.45,1.45,0,0,0-.15-.87A1.72,1.72,0,0,0,332,271.92Zm-2.15,2.51a11.36,11.36,0,0,1-.23-1.22,2.91,2.91,0,0,0-.4-1.27,1,1,0,0,0-.71-.36,1.23,1.23,0,0,0-.88.24,1,1,0,0,0-.41.77,1.7,1.7,0,0,0,.8,1.51,4,4,0,0,0,1.37.29Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M325.27,277.83l7.84.95a9.14,9.14,0,0,1,2.31.49,2.75,2.75,0,0,1,1.46,1.24,3.17,3.17,0,0,1,.3,1.92,3.11,3.11,0,0,1-.93,2.05,2.37,2.37,0,0,1-1.92.54,4,4,0,0,1-1.58-.63,4.35,4.35,0,0,0-1.17-.61,1,1,0,0,0-.5.08,9.74,9.74,0,0,0-1.25.77,6.16,6.16,0,0,1-1.56.81,3,3,0,0,1-1.29.11,2.79,2.79,0,0,1-1.94-1,2.44,2.44,0,0,1,0-3.18,2.88,2.88,0,0,1,1.25-.82l.78,1.51c-.46.14-.7.36-.74.66a.65.65,0,0,0,.18.51,1,1,0,0,0,.59.29,1.22,1.22,0,0,0,.6-.09,9.92,9.92,0,0,0,1.37-.88,3.81,3.81,0,0,1,2.24-1,4.88,4.88,0,0,1,1.55.66c.55.31.89.49,1,.56a1.91,1.91,0,0,0,.47.12.85.85,0,0,0,1-.76.94.94,0,0,0-.15-.65,1.09,1.09,0,0,0-.6-.45,10.22,10.22,0,0,0-1.75-.31l-7.84-.94Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M326.28,291.51l-.68,1.92a3.77,3.77,0,0,1-1.77-1.53,3.32,3.32,0,0,1-.33-2.14,3.26,3.26,0,0,1,1.45-2.42,5.16,5.16,0,0,1,3.61-.56,5.45,5.45,0,0,1,3.35,1.61,3,3,0,0,1,.75,2.53,3.11,3.11,0,0,1-1.51,2.37,6.07,6.07,0,0,1-4,.48l-.26,0,.74-5A2.55,2.55,0,0,0,326,289a1.48,1.48,0,0,0-.74,1.06C325.13,290.68,325.48,291.17,326.28,291.51Zm2.32.45a2.43,2.43,0,0,0,1.61-.23,1.29,1.29,0,0,0,.67-1,1.32,1.32,0,0,0-.38-1.14,2.32,2.32,0,0,0-1.45-.66Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M150.53,133.92l1.25-12.1,2,.2-.49,4.77,3.94.4.49-4.76,2,.2-1.24,12.11-2-.21.54-5.29-3.94-.41-.54,5.3Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M160.54,130.39a5.39,5.39,0,0,1,.75-2.29,3.84,3.84,0,0,1,1.5-1.45,3.39,3.39,0,0,1,1.92-.34,3.22,3.22,0,0,1,2.58,1.64,5.24,5.24,0,0,1,.66,3.28,5.61,5.61,0,0,1-.76,2.4,3.92,3.92,0,0,1-1.53,1.5,3.25,3.25,0,0,1-1.89.36,3.37,3.37,0,0,1-2.53-1.5A5.17,5.17,0,0,1,160.54,130.39Zm1.94.33a3.25,3.25,0,0,0,.31,2.07,1.54,1.54,0,0,0,1.19.81,1.51,1.51,0,0,0,1.3-.56,3.35,3.35,0,0,0,.72-2,3.27,3.27,0,0,0-.31-2,1.48,1.48,0,0,0-2.49-.26A3.34,3.34,0,0,0,162.48,130.72Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M169.06,127l1.06.1.07-.66a4.85,4.85,0,0,1,.4-1.71,1.78,1.78,0,0,1,.82-.76,2.43,2.43,0,0,1,1.29-.18,4.59,4.59,0,0,1,1.56.46l-.41,1.59a3.31,3.31,0,0,0-.87-.23.75.75,0,0,0-.62.17,1.76,1.76,0,0,0-.27.9l-.06.62,1.42.15-.19,1.82-1.42-.14-.72,6.94-1.9-.2.71-6.94-1.06-.11Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M176.75,124.39l-.45,4.44a3.2,3.2,0,0,1,1.1-.89,2.46,2.46,0,0,1,1.23-.2,2.31,2.31,0,0,1,1.39.6,2.59,2.59,0,0,1,.69,1.38,8.24,8.24,0,0,1,0,2.1l-.53,5.15-1.91-.2.48-4.64a5.76,5.76,0,0,0,0-2,1,1,0,0,0-.92-.65,1.35,1.35,0,0,0-1.21.47,4.25,4.25,0,0,0-.63,2.17l-.45,4.4-1.91-.2,1.25-12.1Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M184.13,131.21l-1.69-.56a3.35,3.35,0,0,1,1.24-1.8,3.25,3.25,0,0,1,2.13-.37,4,4,0,0,1,1.81.53,2,2,0,0,1,.81,1,5.79,5.79,0,0,1,.06,2.15l-.3,2.71a10.32,10.32,0,0,0-.08,1.71,5.78,5.78,0,0,0,.21,1.22l-1.88-.19-.15-1a3.61,3.61,0,0,1-1.13.77,2.67,2.67,0,0,1-1.22.17,2.25,2.25,0,0,1-1.64-.89,2.61,2.61,0,0,1-.48-2,2.93,2.93,0,0,1,.41-1.27,2.13,2.13,0,0,1,.86-.8,5.06,5.06,0,0,1,1.64-.43,8.76,8.76,0,0,0,1.9-.38,3,3,0,0,0,0-1,.88.88,0,0,0-.34-.41,1.76,1.76,0,0,0-.77-.22,1.27,1.27,0,0,0-.83.14A1.7,1.7,0,0,0,184.13,131.21Zm2.35,2.15a8.21,8.21,0,0,1-1.17.24,2.63,2.63,0,0,0-1.21.39,1.05,1.05,0,0,0-.35.7,1.26,1.26,0,0,0,.22.88,1,1,0,0,0,.72.42,1.37,1.37,0,0,0,.83-.19,1.47,1.47,0,0,0,.61-.6,4.25,4.25,0,0,0,.3-1.37Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M189.79,129.1l2,.21,1.07,6.4,2.32-6.05,2,.2L193.78,138l-.66,1.59a5.45,5.45,0,0,1-.78,1.29,2.12,2.12,0,0,1-.88.58,2.83,2.83,0,0,1-1.23.1,4.28,4.28,0,0,1-1.07-.25l0-1.83a4.06,4.06,0,0,0,.79.19,1.24,1.24,0,0,0,.93-.27,3,3,0,0,0,.72-1.26Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M198.26,130l1.75.18-.12,1.2a3.52,3.52,0,0,1,1.14-.95,2.24,2.24,0,0,1,1.25-.22,2,2,0,0,1,1.16.47,2.36,2.36,0,0,1,.72,1.13,3.56,3.56,0,0,1,1.14-.94,2.12,2.12,0,0,1,1.2-.23,2.32,2.32,0,0,1,1.24.48,2.22,2.22,0,0,1,.7,1.09,5.55,5.55,0,0,1,.07,2l-.58,5.61-1.9-.2.51-5a4.24,4.24,0,0,0,0-1.77.82.82,0,0,0-.73-.51,1.17,1.17,0,0,0-1.09.5,5,5,0,0,0-.63,2.34l-.43,4.21-1.91-.19.49-4.81a6.9,6.9,0,0,0,0-1.86.83.83,0,0,0-.78-.64,1.23,1.23,0,0,0-.8.21,1.54,1.54,0,0,0-.6.77,7.48,7.48,0,0,0-.35,1.81l-.44,4.27-1.9-.2Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M214.3,137.67l1.85.58a3.77,3.77,0,0,1-1.39,1.84,3.36,3.36,0,0,1-4.37-.88,5.29,5.29,0,0,1-.66-3.58,5.64,5.64,0,0,1,1.39-3.42,2.76,2.76,0,0,1,2.38-.88,2.91,2.91,0,0,1,2.3,1.39,6.32,6.32,0,0,1,.61,4l0,.26-4.76-.49a2.68,2.68,0,0,0,.3,1.67,1.35,1.35,0,0,0,1,.68C213.55,138.86,214,138.49,214.3,137.67Zm.34-2.34a2.57,2.57,0,0,0-.28-1.59,1.22,1.22,0,0,0-.93-.62,1.25,1.25,0,0,0-1.07.43,2.42,2.42,0,0,0-.57,1.48Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M219.37,141l-1.91-.19.91-8.77,1.76.18-.13,1.25a4,4,0,0,1,.94-1.08,1.29,1.29,0,0,1,.86-.21,2.16,2.16,0,0,1,1.2.57l-.8,2a1.52,1.52,0,0,0-.86-.47,1,1,0,0,0-.73.22,2,2,0,0,0-.54,1,19.87,19.87,0,0,0-.42,2.81Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M235.26,142.64l-2.18-.22-.58-2.83-4-.41-1.1,2.65-2.13-.22,5.11-11.7,2.12.22Zm-3.2-5.17-.91-4.63-1.8,4.35Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1" d="M236.3,142.75l1.24-12.11,1.91.2-1.25,12.1Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1" d="M240.15,143.14,241.4,131l1.91.19-1.25,12.11Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M248.47,141.19l1.85.58a3.7,3.7,0,0,1-1.38,1.84,3.05,3.05,0,0,1-2,.44,3,3,0,0,1-2.35-1.32,5.35,5.35,0,0,1-.67-3.58,5.59,5.59,0,0,1,1.4-3.42,2.75,2.75,0,0,1,2.37-.88,2.9,2.9,0,0,1,2.31,1.39,6.3,6.3,0,0,1,.61,3.95l0,.27-4.76-.49a2.63,2.63,0,0,0,.3,1.66,1.35,1.35,0,0,0,1,.69C247.73,142.38,248.18,142,248.47,141.19Zm.35-2.35a2.6,2.6,0,0,0-.28-1.59,1.21,1.21,0,0,0-2-.18,2.39,2.39,0,0,0-.57,1.48Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M256.19,142l1.85.58a3.77,3.77,0,0,1-1.39,1.84,3,3,0,0,1-2,.44,3,3,0,0,1-2.36-1.32,5.35,5.35,0,0,1-.67-3.58,5.64,5.64,0,0,1,1.4-3.42,2.78,2.78,0,0,1,2.38-.88,2.91,2.91,0,0,1,2.3,1.39,6.32,6.32,0,0,1,.61,4l0,.26-4.76-.49a2.68,2.68,0,0,0,.3,1.67,1.35,1.35,0,0,0,1,.68C255.44,143.17,255.9,142.8,256.19,142Zm.34-2.34a2.57,2.57,0,0,0-.28-1.59,1.22,1.22,0,0,0-.93-.62,1.25,1.25,0,0,0-1.07.43,2.42,2.42,0,0,0-.57,1.49Z"
                  transform="translate(-97.07 -94.33)"/>
            <a href='https://goo.gl/maps/SFBCC5YCf62A6B6w7' style={{textDecoration: 'none'}} target='_blank'
               rel='noreferrer'>
                <path className="cls-1 pin" style={{fill: '#164673'}}
                      d="M274.56,296.53a3.58,3.58,0,0,0,6,0L313.2,247a42.07,42.07,0,1,0-71.28,0Zm3-86.63a14.88,14.88,0,1,1-14.87,14.88A14.87,14.87,0,0,1,277.55,209.9Z"
                      transform="translate(-97.07 -94.33)"/>
            </a>
            <path className="cls-1"
                  d="M268,129.11a1.52,1.52,0,0,1,1.3,1c1,2.71,2,5.5,3,8.37,4.24,11.75,9,25,13.46,38.05a1.52,1.52,0,0,0,1.16,1,48,48,0,0,1,21,9.93,1.54,1.54,0,0,0,2.42-1.67c-5.53-17.25-12.39-36.3-18.85-54.23l-.14-.38c23.52,2,47.19,3.76,68.8,4.94a1.53,1.53,0,0,0,1.61-1.45l.92-17.33a1.52,1.52,0,0,0-1.44-1.61c-23.93-1.31-50.44-3.34-76.46-5.62a1.55,1.55,0,0,1-1.32-1c-1.8-5.15-3.37-9.76-4.61-13.68a1.53,1.53,0,0,0-1.91-1L260.4,99.54a1.53,1.53,0,0,0-1,1.92c.43,1.36.9,2.8,1.39,4.3a1.54,1.54,0,0,1-1.6,2c-44.7-4.21-84.74-8.68-103.3-10.75l-3.34-.38a1.54,1.54,0,0,0-1.7,1.36l-1.91,17.25a1.52,1.52,0,0,0,1.35,1.69l3.33.37C173.71,119.57,218.86,124.61,268,129.11Z"
                  transform="translate(-97.07 -94.33)"/>
            <path className="cls-1"
                  d="M396.57,329.05c-13.25-5.67-56.71-20.48-79.14-26.92a1.52,1.52,0,0,1-1.09-1.67c2.34-17.68,4.16-34.93,4.91-49.37a1.52,1.52,0,0,0-2.82-.86l0,.06,0,.06,0,.07-20,30.23a1.48,1.48,0,0,0-.25.67c-1.47,13-3.42,27.42-5.77,42.48a1.53,1.53,0,0,1-2.61.85c-40.31-40.83-139.53-140.91-164.94-162.77a1.53,1.53,0,0,0-2.17.18l-5.9,7a1.52,1.52,0,0,0,.17,2.14c6.59,5.67,18.46,16.94,33.09,31.17a1.53,1.53,0,0,1,.23,1.92c-7.39,11.58-31,44.53-52.84,74.31a1.53,1.53,0,0,0,.34,2.14l7.4,5.44a1.52,1.52,0,0,0,2.14-.33c11.15-15.19,39.71-54.31,51.52-72.34a1.52,1.52,0,0,1,2.35-.25c24.39,23.93,53.86,53.41,79.21,78.91a1.52,1.52,0,0,1,.29,1.73c-13,27.15-45.61,78.34-51.21,87.08a1.53,1.53,0,0,0,.47,2.11l7.72,5a1.53,1.53,0,0,0,2.12-.46c5.47-8.53,34.9-54.69,49.55-83.56a1.54,1.54,0,0,1,2.47-.39c15.62,15.75,28.85,29.14,37.11,37.51a1.48,1.48,0,0,1,.41,1.33q-1.32,7.74-2.77,15.56c-2.45,13.2-7.3,31.36-9.27,38.54a1.53,1.53,0,0,0,1.07,1.88L295,403.15a1.53,1.53,0,0,0,1.89-1.07c2-7.4,7.08-26.28,9.67-40.26s5.25-29.85,7.63-46.05a1.54,1.54,0,0,1,1.95-1.25c3.05.9,6.47,1.94,10.13,3.08a1.56,1.56,0,0,1,.9.73c4.45,8.19,17.7,31.3,34,47.72a1.55,1.55,0,0,0,2.19,0l6.43-6.56a1.53,1.53,0,0,0,0-2.15c-9.07-9.15-17.28-20.84-23.29-30.34a1.54,1.54,0,0,1,1.79-2.28c17.68,5.88,35.48,12.18,43.27,15.53a1.54,1.54,0,0,0,2-.78l3.81-8.35A1.53,1.53,0,0,0,396.57,329.05Z"
                  transform="translate(-97.07 -94.33)"/>
        </svg>
    )
}
