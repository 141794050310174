import * as React from "react";

const CardHashtag = ({href, hashtag, sx}) =>
    <a href={href}
       style={{textDecoration: 'none'}}
       children={
           <CardHashtagText hashtag={hashtag}
                            sx={{color: '#2277BD', "&&:hover": {color: '#164673'}, ...sx}}/>}/>


const CardHashtagText = ({hashtag, sx}) =>
    <span children={hashtag}
          style={{display: 'inline-block', fontSize: '.8rem', paddingLeft: '.2rem', ...sx}}/>

function Hashtags({hashtags}) {
    if (!Array.isArray(hashtags)) {
        return <></>
    }

    return <>
        {hashtags.map((hashtag, i) => {
            let tag = hashtag.substring(1).toLowerCase()

            switch (true) {
                case ['aktivitaeten'].includes(tag) :
                    return <CardHashtag key={i} href={'#aktivitaeten'} hashtag={hashtag}/>
                case ['brauchtum'].includes(tag) :
                    return <CardHashtag key={i} href={'#brauchtum'} hashtag={hashtag}/>
                case ['vorstand'].includes(tag) :
                    return <CardHashtag key={i} href={'#vorstand'} hashtag={hashtag}/>

                case ['tanzen'].includes(tag) :
                    return <CardHashtag key={i} href={'/tanzen'} hashtag={hashtag}/>
                case ['vereinsleben'].includes(tag) :
                    return <CardHashtag key={i} href={'/vereinsleben'} hashtag={hashtag}/>
                case ['gloecklerlauf'].includes(tag) :
                    return <CardHashtag key={i} href={'/gloecklerlauf'} hashtag={hashtag}/>
                case ['maibaum'].includes(tag) :
                    return <CardHashtag key={i} href={'/maibaum'} hashtag={hashtag}/>
                case ['fackeltanz'].includes(tag) :
                    return <CardHashtag key={i} href={'/fackeltanz'} hashtag={hashtag}/>
                case ['wildejagd'].includes(tag) :
                    return <CardHashtag key={i} href={'/wildeJagd'} hashtag={hashtag}/>

                default :
                    return <CardHashtagText key={i} hashtag={hashtag}/>
            }
        })}
    </>
}

export {Hashtags}
