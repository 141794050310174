import {Grid, Skeleton} from "@mui/material";
import * as React from "react";
import {Absatz, CardText, CardTitle, Poem, SourceText, Subtitle, Title} from "../util/Font";
import {Helmet} from "react-helmet";
import {Gallery} from "../util/Gallery";

function Figuren({subtext}) {
    return (
        <>
            <Subtitle children={subtext?.title} sx={{fontWeight: '', paddingTop: '1rem'}}/>
            <Absatz children={subtext?.text}/>
            <br/>
            {subtext?.subtext.map((figur, i) => <Figur key={i} figur={figur}/>)}
        </>
    )
}

function Figur({figur}) {
    if (figur.img2) {
        return (
            <Grid container flexDirection='row' columnGap='1rem' paddingBottom='1rem' rowGap='1rem'>
                <Helmet>
                    <title>Jung Alpenland - Wilde Jagd</title>
                </Helmet>
                <Grid item height='10rem' container columnGap='1rem' justifyContent='flex-start' flexBasis='content'>
                    {figur?.img
                        ? <img src={`assets/img/wildeJagd/${figur?.img}`} alt={figur?.title}
                               style={{maxWidth: '100%', maxHeight: '100%'}}/>
                        : <Skeleton height='100%' width='7rem' variant='rectangular' animation={false}/>}
                    {figur?.img2
                        ? <img src={`assets/img/wildeJagd/${figur?.img2}`} alt={figur?.title}
                               style={{maxWidth: '100%', maxHeight: '100%'}}/>
                        : <Skeleton height='100%' width='7rem' variant='rectangular' animation={false}/>}
                </Grid>
                <Grid item xs={12} sm={true}>
                    <CardTitle children={figur?.title} sx={{textAlign: 'left'}}/>
                    <CardText children={figur?.text} sx={{textAlign: 'left'}}/>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container flexDirection='row' paddingBottom='1rem' columnGap='1rem'>
            <Grid item height='10rem' container justifyContent='flex-start' flexBasis='content'>
                {figur?.img
                    ? <img src={`assets/img/wildeJagd/${figur?.img}`} alt={figur?.title}
                           style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    : <Skeleton height='100%' width='7rem' variant='rectangular' animation={false}/>}
            </Grid>
            <Grid item xs={true}>
                <CardTitle children={figur?.title} sx={{textAlign: 'left'}}/>
                <CardText children={figur?.text} sx={{textAlign: 'left'}}/>
            </Grid>
        </Grid>
    )
}

function WildeJagd() {
    const [text, setText] = React.useState()
    React.useEffect(() => {
        fetch(`assets/data/sub_WildeJagd.json`)
            .then((r) => r.json())
            .then((text) => setText(text))
    }, [])

    return (
        <Grid container item maxWidth='120rem' flexDirection='column' flexGrow={1} alignItems='center' padding='1rem'
              paddingBottom='5rem'
              sx={{
                  backgroundImage: `url(assets/img/SUB-WildeJagd.png)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto',
              }}>
            <Grid item maxWidth='20rem' width='70%' paddingTop='1rem'
                  component='img' src={'assets/img/logo/logo.svg'}
                  sx={{display: {xs: 'flex', md: 'none'}, zIndex: '1'}}
            />
            <Grid item maxWidth='50rem' sx={{
                marginTop: {
                    xs: '2rem',
                    md: '10rem'
                },
                backgroundColor: 'rgba(255,255,255,0.8)',
                boxShadow: '0rem 2rem 6rem 8rem rgba(255,255,255,0.8)',
            }}
                  textAlign='center'>
                <Title children={text?.title}/>
                <Gallery galleryFile='gallery_WildeJagd.json' galleryFolder='WildeJagd'/>
                {text?.text.split('\n').map((line, i) =>
                    <Absatz key={i} children={line}/>
                )}
                <Subtitle children={text?.ablauf?.title}/>
                {text?.ablauf?.text.split('\n').map((line, i) =>
                    <Absatz key={i} children={line}/>
                )}
                <Subtitle children={text?.figuren?.title}/>
                {text?.figuren?.text.map((subtext, i) =>
                    <Figuren key={i} subtext={subtext}/>)}
                <Absatz children={text?.poem?.subtitle}/>
                <br/>
                {text?.poem?.text.split('\n').map((line, i) => {
                    return line === ""
                        ? <br key={i}/>
                        : <Poem key={i} children={line}/>
                })}
                <SourceText children={text?.sources}/>
            </Grid>
        </Grid>
    )
}

export {WildeJagd}
