import * as React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, Pagination} from "swiper";
import {Paper} from "@mui/material";

function Gallery({galleryFile, galleryFolder}) {
    const [imgSources, setImgSources] = React.useState([])
    React.useEffect(() => {
        fetch(`assets/data/${galleryFile}`)
            .then((r) => r.json())
            .then((imgSources) => setImgSources(imgSources))
    }, [galleryFile])

    return (
        <Paper elevation={3}>
            <Swiper
                slidesPerView={'auto'}
                pagination={{clickable: true}}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                style={{margin: '.35rem', padding: 0, zIndex: 0}}>
                {imgSources.map((image, index) =>
                    <SwiperSlide key={index}>
                        <img src={`assets/img/gallery/${galleryFolder}/${image}`} width='100%' alt={image}
                             style={{marginTop: '.35rem'}}/>
                    </SwiperSlide>
                )}

            </Swiper>
        </Paper>
    )
        ;
}

export {Gallery}