import * as React from "react";
import {NavMobile} from "./NavMobile";
import {NavDesktop} from "./NavDesktop";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import {KeyboardArrowDown} from "@mui/icons-material";

function Navigation({contactForm}) {
    const navItems = [
        {
            title: "Startseite",
            path: "/#",
            left: true,
            icon: <HomeIcon/>
        },
        {
            title: "Aktivitäten",
            path: "/#aktivitaeten",
            icon: <KeyboardArrowDown/>,
            children: [
                {
                    title: "Tanzen",
                    path: "/tanzen"
                },
                {
                    title: "Vereinsleben",
                    path: "/vereinsleben"
                }
            ]
        },
        {
            title: "Brauchtum",
            path: "/#brauchtum",
            icon: <KeyboardArrowDown/>,
            children: [
                {
                    title: "Glöcklerlauf",
                    path: "/gloecklerlauf"
                },
                {
                    title: "Maibaum",
                    path: "/maibaum"
                },
                {
                    title: "Fackeltanz",
                    path: "/fackeltanz"
                },
                {
                    title: "Wilde Jagd",
                    path: "/wildeJagd"
                }
            ]
        },
        {
            title: "Vorstand",
            path: "/#vorstand",
            right: true,
            icon: <GroupsIcon/>
        }
    ]

    return (
        <>
            <NavDesktop items={navItems} contactForm={contactForm}/>
            <NavMobile items={navItems} contactForm={contactForm}/>
        </>
    );
}

export {Navigation}
