import emailjs from "@emailjs/browser";

function sendEmail(form, recaptchaRef) {
    const params = {
        ...form,
        "g-recaptcha-response": recaptchaRef
    }

    return emailjs
        .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            params,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
}

export {sendEmail}
