import {Button, FormHelperText, Grid, MenuItem, TextField,} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as EmailValidatior from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";
import {sendEmail} from "../../hooks/useEmailJs";

const contactFormSchema = yup.object().shape({
    firstName: yup.string()
        .matches("^[a-zA-zÄÖÜäöü\\-\\ ]*$", "Keine Sonderzeichen erlaubt"),
    lastName: yup.string()
        .matches("^[a-zA-zÄÖÜäöü\\-\\ ]*$", "Keine Sonderzeichen erlaubt"),
    replyTo: yup.string().required("Geben Sie Ihre E-Mail Adresse ein")
        .test(
            "isValidEmail",
            "Geben Sie eine gültige E-Mail Adresse ein",
            function test(email) {
                return EmailValidatior.validate(email);
            }
        ),
    topic: yup.string().required("Wählen Sie ein Thema aus"),
    message: yup.string().required("Geben Sie Ihre Nachricht ein"),
});

function ContactForm({topics}) {
    const [recaptchaRef, setRecaptchaRef] = React.useState(null)
    const [emailSent, setEmailSent] = React.useState(undefined);
    const {
        register,
        formState: {errors, isSubmitSuccessful},
        handleSubmit,
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(contactFormSchema),
    });

    const handleTopicChange = (event) => setValue("topic", event.target.value);

    const sendMessage = (form) => {
        sendEmail(form, recaptchaRef)
            .then(
                () => {
                    setEmailSent(true);
                    setRecaptchaRef(null);
                    reset();
                    window.grecaptcha.reset()
                },
                () => setEmailSent(false)
            );
    };

    const isSending = (isSubmitSuccessful && (emailSent === undefined || emailSent))

    const [scale, setScale] = React.useState('1');

    function determineScale(width) {
        const scale = width / 304
        return scale > 1
            ? '1'
            : scale.toString()
    }

    React.useEffect(() => setScale(determineScale(document.getElementById("recaptcha").clientWidth)), [])

    return (
        <Grid container justifyContent="center" component="form"
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(sendMessage)}
              spacing={1}>
            <Grid item container spacing={1}>
                <Grid item xs={12} md={12}>
                    <TextField
                        select
                        label="Thema"
                        onChange={handleTopicChange}
                        required
                        fullWidth
                        error={Boolean(errors?.topic)}
                        disabled={isSending}
                        defaultValue=""
                        {...register("topic")}
                    >
                        <MenuItem value="Allgemeines">
                            Allgemeines
                        </MenuItem>
                        {topics?.map((item, i) => (
                            <MenuItem key={i} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                    {errors?.topic && (
                        <FormHelperText>{errors?.topic?.message}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Vorname"
                        fullWidth
                        error={Boolean(errors?.firstName)}
                        disabled={isSending}
                        {...register("firstName")}
                    />
                    {errors?.firstName && (
                        <FormHelperText>{errors?.firstName?.message}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Nachname"
                        fullWidth
                        error={Boolean(errors?.lastName)}
                        disabled={isSending}
                        {...register("lastName")}
                    />
                    {errors?.lastName && (
                        <FormHelperText>{errors?.lastName?.message}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="E-Mail"
                        required
                        fullWidth
                        error={Boolean(errors?.replyTo)}
                        disabled={isSending}
                        {...register("replyTo")}
                    />
                    {errors?.replyTo && (
                        <FormHelperText>{errors?.replyTo?.message}</FormHelperText>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Nachricht"
                    required
                    fullWidth
                    multiline
                    error={Boolean(errors?.message)}
                    disabled={isSending}
                    minRows='4'
                    {...register("message")}
                />
                {errors?.message && (
                    <FormHelperText>{errors?.message?.message}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
                <FormHelperText>
                    * Pflichtfeld
                </FormHelperText>
            </Grid>
            <Grid item container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} md={6} sx={{
                    transformOrigin: '0 0',
                    transform: `scale(${scale})`
                }} id="recaptcha">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={setRecaptchaRef}
                        hl="de"
                    />
                </Grid>
                <Grid item container xs={12} md={6} justifyContent="flex-end" alignItems="flex-end" direction="row"
                      spacing="12">
                    <Grid item>
                        {isSubmitSuccessful && (emailSent === undefined || emailSent) && (
                            <FormHelperText>
                                Nachricht wird versendet
                            </FormHelperText>
                        )}
                        {emailSent === false && (
                            <FormHelperText>
                                Nachricht konnte nicht versandt werden
                            </FormHelperText>
                        )}
                        {emailSent === true && !isSubmitSuccessful && (
                            <FormHelperText sx={{color: '#159549'}}>
                                Nachricht wurde versandt
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isSending || !recaptchaRef}
                            sx={{
                                marginTop: "auto",
                                backgroundColor: '#2277BD',
                                "&&:hover": {
                                    backgroundColor: '#164673'
                                }
                            }}
                        >
                            Absenden
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHelperText sx={{textAlign: 'right'}}>
                            Ich erhalte eine Kopie der Nachricht.
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export {ContactForm}
