import {Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import * as React from "react";

function Tabs({selection, changeSelection, aktivitaeten}) {
    return (
        <Grid container justifyContent='center'>
            <Divider sx={{width: '90%'}}/>
            <Grid item container flexDirection='row' width='90%'>
                {aktivitaeten.map((item, i) =>
                    <Grid key={i}
                          item flexBasis='content' xs={true}
                          component={Button} fullWidth
                          onClick={changeSelection(i)}
                          sx={{
                              pt: '.75rem', pb: '.75rem',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              color: (selection === i) ? '#164673' : '#808080',
                              "&&:hover": {color: '#2277BD'}
                          }}>
                        {item.title}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export {Tabs}
