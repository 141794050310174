import {Grid} from "@mui/material";
import {MediaIcons} from "../util/MediaIcons";
import Map from "./Map";
import {FooterLink, FooterText, FooterTitle} from "../util/Font";

function MediaContactDSGVO({contactForm}) {
    return (
        <Grid item flexBasis='content'>
            <FooterTitle children='Folge & Kontaktiere uns'/>
            <MediaIcons variant='footer' contactForm={contactForm} sx={{marginBottom: '1rem'}}/>
            <FooterTitle children='Datenschutz' sx={{marginTop: '2rem'}}/>
            <a href='/dsgvo' style={{textDecoration: 'none'}}>
                <FooterLink children='zur Datenschutzerklärung' sx={{marginBottom: '1rem'}}/>
            </a>
        </Grid>
    )
}

function AdresseImpressumStatuten() {
    return (
        <Grid item flexBasis='content'
              container flexDirection='row' spacing='1rem' flexWrap='nowrap'>
            <Grid item>
                <FooterTitle children='Adresse & Impressum'/>
                <FooterText>
                    Brauchtumsgruppe Jung Alpenland<br/>
                    Nonntaler Hauptstraße 86, 2.OG<br/>
                    5020 Salzburg
                </FooterText>
                <FooterTitle children='Statuten' sx={{marginTop: '.98rem'}}/>
                <a
                    href='assets/pdf/JungAlpenland_Statuten.pdf'
                    target='_blank' rel='noreferrer'
                    style={{textDecoration: 'none'}}>
                    <FooterLink children='zum Download' sx={{marginBottom: '1rem'}}/>
                </a>
            </Grid>
            <Grid item width='12rem' maxHeight='14rem'>
                <Map/>
            </Grid>
        </Grid>
    )
}


function Footer({contactForm}) {
    return (
        <Grid container item xs={12} justifyContent="center" minHeight="10rem" alignSelf='flex-end'
              sx={{backgroundColor: '#101010', zIndex: '1'}}>
            <Grid item container maxWidth='44rem' columnGap='2rem' paddingTop='2rem' padding='1rem'
                  sx={{justifyContent: 'flex-start'}}>
                <MediaContactDSGVO contactForm={contactForm}/>
                <AdresseImpressumStatuten/>
                <Grid item>
                    <FooterText>Fotos von
                        <a
                            href='https://www.flausen.at/' target='_blank' rel='noreferrer'
                            style={{textDecoration: 'none', color: 'white'}}
                        > „Foto Flausen“ </a>
                        - © Jung Alpenland</FooterText>
                </Grid>
            </Grid>
        </Grid>
    )
}

export {Footer}
