import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Navigation} from "./components/navigation/Navigation";
import {Grid} from "@mui/material";
import {Footer} from "./components/footer/Footer";
import "./App.css"
import {Home} from "./components/home/Home";
import {WildeJagd} from "./components/brauchtum/WildeJagd";
import {Tanzen} from "./components/aktivitaeten/Tanzen";
import {Vereinsleben} from "./components/aktivitaeten/Vereinsleben";
import {Gloecklerlauf} from "./components/brauchtum/Gloecklerlauf";
import {Fackeltanz} from "./components/brauchtum/Fackeltanz";
import {Maibaum} from "./components/brauchtum/Maibaum";
import {DSVGO} from "./components/DSVGO";
import React from "react";
import {ContactFormSlide} from "./components/util/ContactFormSlide";
import {Helmet} from "react-helmet";

function App() {
    const [open, setOpen] = React.useState(false);

    return (
        <Grid className="App" container flexDirection='column' alignItems='center' minHeight='100%'>
            <Helmet>
                <title>Jung Alpenland</title>
            </Helmet>
            <Navigation contactForm={{setOpen}}/>
            <ContactFormSlide contactForm={{open, setOpen}}/>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home contactForm={{setOpen}}/>}/>
                    <Route path="/tanzen" element={<Tanzen/>}/>
                    <Route path="/vereinsleben" element={<Vereinsleben/>}/>
                    <Route path="/gloecklerlauf" element={<Gloecklerlauf/>}/>
                    <Route path="/maibaum" element={<Maibaum/>}/>
                    <Route path="/fackeltanz" element={<Fackeltanz/>}/>
                    <Route path="/wildeJagd" element={<WildeJagd/>}/>
                    <Route path="/dsgvo" element={<DSVGO/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </BrowserRouter>
            <Footer contactForm={{setOpen}}/>
        </Grid>
    );
}

export default App;
