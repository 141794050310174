import {Avatar, Grid, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import * as React from "react";
import {useState} from "react";
import {CardLink, CardText} from "../../util/Font";
import {Hashtags} from "./Hashtags";

function InstagramMobile({data, contactForm, hashtag}) {
    const [activeStep, setActiveStep] = useState(0);
    const [activeImage, setActiveImage] = useState(0);

    if (!data || !data[activeStep]) return null
    const maxSteps = data?.length;
    let maxImages = data[activeStep]?.children?.data?.length || 1;

    const isTermin = data[activeStep]?.hashtags?.includes(hashtag);
    const isCurrentTermin = isTermin && activeStep === 0;

    const determineMediaType = (url, media_type) => {
        return media_type === 'VIDEO'
            ? <video src={url} width='100%' controls controlsList="nodownload nofullscreen noplaybackrate" id="media"
                     style={{
                         borderTopLeftRadius: '4px',
                         borderTopRightRadius: '4px',
                         width: '100%',
                         height: 'auto',
                         objectFit: 'contain',
                         backgroundColor: '#101010',
                         aspectRatio: "1/1",
                     }}/>
            : <img src={url} width='100%' alt='' id="media"
                   style={{
                       borderTopLeftRadius: '4px',
                       borderTopRightRadius: '4px',
                       width: '100%',
                       height: 'auto',
                       objectFit: 'cover',
                       aspectRatio: '1/1'
                   }}/>
    }

    const handleNext = () =>
        activeStep === maxSteps - 1
            ? setActiveStep(0)
            : setActiveStep(current => current + 1)

    const handleBack = () =>
        activeStep === 0
            ? setActiveStep(maxSteps - 1)
            : setActiveStep(current => current - 1)

    const handleNextImage = () => {
        activeImage === maxImages - 1
            ? setActiveImage(0)
            : setActiveImage(current => current + 1)
    }

    const handleBackImage = () => {
        activeImage === 0
            ? setActiveImage(maxImages - 1)
            : setActiveImage(current => current - 1)
    }

    return (
        <Grid item container flexDirection='column'
              component={Paper} elevation={3}
              maxWidth='35rem' mt='1rem' mb='1rem' padding='.35rem' paddingTop='0'
              sx={{display: {xs: 'flex', md: 'none'}}}>
            {data?.length > 1 &&
                <MobileStepper variant='dots' position='static'
                               steps={maxSteps} activeStep={activeStep}
                               sx={{p: 0, m: 0, flexGrow: 1}}
                               backButton={
                                   <Button onClick={handleBack}
                                           sx={{p: '.5rem 0 .5rem 0', flexGrow: 1}}>
                                       <KeyboardArrowLeft
                                           sx={{
                                               fontSize: '2rem',
                                               color: '#808080',
                                               "&&:hover": {color: '#2277BD'}
                                           }}/>
                                   </Button>
                               }
                               nextButton={
                                   <Button onClick={handleNext}
                                           sx={{p: '.5rem 0 .5rem 0', flexGrow: 1}}>
                                       <KeyboardArrowRight
                                           sx={{
                                               fontSize: '2rem',
                                               color: '#303030',
                                               "&&:hover": {color: '#2277BD'}
                                           }}/>
                                   </Button>
                               }/>}
            <Grid item container justifyContent="center" position="relative">
                {determineMediaType(
                    data[activeStep]?.children?.data[activeImage]?.media_url || data[activeStep]?.media_url,
                    data[activeStep]?.children?.data[activeImage]?.media_type || data[activeStep]?.media_type
                )}
                {data[activeStep]?.children?.data?.length > 1 &&
                    <Grid item container justifyContent='space-between' marginTop='-100%'>
                        <Button onClick={handleBackImage} style={{height: '60%', marginTop: '20%'}}>
                            <Avatar
                                sx={{
                                    width: '1.5rem',
                                    height: '1.5rem',
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(255,255,255,0.75)'
                                }}>
                                <KeyboardArrowLeft
                                    sx={{fontSize: '1.5rem', color: 'rgba(0,0,0,0.75)'}}/>
                            </Avatar>
                        </Button>
                        <Button onClick={handleNextImage} style={{height: '60%', marginTop: '20%'}}>
                            <Avatar
                                sx={{
                                    width: '1.5rem',
                                    height: '1.5rem',
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(255,255,255,0.75)',
                                }}>
                                <KeyboardArrowRight
                                    sx={{fontSize: '2rem', color: 'rgba(0,0,0,0.75)'}}/>
                            </Avatar>
                        </Button>
                    </Grid>
                }
            </Grid>
            <CardText sx={{padding: '.65rem', minHeight: '6.6rem'}}>
                {data[activeStep]?.timestamp &&
                    <span children={new Date(data[activeStep].timestamp).toLocaleDateString('de-AT')}
                          style={{display: 'block', fontSize: '.7rem', color: 'grey'}}/>
                }
                {data[activeStep].caption && data[activeStep].caption.split('\n\n')
                    .map((line, i) =>
                        <React.Fragment key={i}>{line}<br/></React.Fragment>)}
                <Hashtags hashtags={data[activeStep].hashtags}/>
            </CardText>
            {isCurrentTermin &&
                <CardLink children='zur Anmeldung' onClick={() => contactForm.setOpen(true)}
                          sx={{
                              cursor: 'pointer',
                              alignSelf: 'center',
                              paddingBottom: {xs: '.75rem', sm: '1rem'}
                          }}/>
            }
        </Grid>

    )
}

export {InstagramMobile}
