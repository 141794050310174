function fetchMedia() {
    return fetchRetry(`${process.env.REACT_APP_BACKEND_URL}/data`, 3);
}

const fetchRetry = (url, retries) =>
    fetch(url)
        .then(response => {
            if (response.ok) return response.json()
            if (retries > 0) return fetchRetry(url, retries - 1)
            throw new Error(response.status)
        })
        .catch(error => console.error(error.message))

export {fetchMedia};
