import {Avatar, Grid, Paper, Skeleton} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {CardLink, CardText} from "../../util/Font";
import {Hashtags} from "./Hashtags";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const TextSkeleton = () =>
    <>
        <Skeleton variant='text' width='100%' animation={false}/>
        <Skeleton variant='text' width='100%' animation={false}/>
        <Skeleton variant='text' width='100%' animation={false}/>
        <Skeleton variant='text' width='50%' animation={false}/>
    </>

const PostSkeleton = () =>
    <Grid item container width='25rem' component={Paper} elevation={3}>
        <Skeleton variant='rectangular' width='100%' height='16.6667rem' animation={false}/>
        <CardText sx={{padding: '1rem', width: '100%', minHeight: '8.1rem'}}>
            <TextSkeleton/>
        </CardText>
    </Grid>

const determineMediaType = (url, media_type) => {
    return media_type === 'VIDEO'
        ? <video src={url} width='100%' controls controlsList="nodownload noplaybackrate"
                 style={{
                     borderTopLeftRadius: '4px',
                     borderTopRightRadius: '4px',
                     width: '24.3rem',
                     height: '24.3rem',
                     objectFit: 'contain',
                     backgroundColor: '#101010'
                 }}/>
        : <img src={url} alt=''
               style={{
                   borderTopLeftRadius: '4px',
                   borderTopRightRadius: '4px',
                   width: '24.3rem',
                   height: '24.3rem',
                   objectFit: 'cover'
               }}/>
}

const Post = ({data, contactForm, first, hashtag}) => {
    const [activeImage, setActiveImage] = useState(0);

    if (!data) {
        return <PostSkeleton/>
    }

    const maxImages = data?.children?.data?.length;

    const isTermin = data?.hashtags?.includes(hashtag);
    let isCurrentTermin = isTermin && first;

    const handleNext = () =>
        activeImage === maxImages - 1
            ? setActiveImage(0)
            : setActiveImage(current => current + 1)

    const handleBack = () =>
        activeImage === 0
            ? setActiveImage(maxImages - 1)
            : setActiveImage(current => current - 1)

    return (
        <Grid item container justifyContent='center' width='25rem' padding='.35rem'
              component={Paper} elevation={isCurrentTermin ? 24 : 3}>
            {determineMediaType(
                data?.children?.data[activeImage]?.media_url || data.media_url,
                data?.children?.data[activeImage]?.media_type || data.media_type
            )}
            {data?.children &&
                <Grid item container justifyContent='space-between' marginTop='-24.3rem'>
                    <Button onClick={handleBack} style={{height: '60%', marginTop: '20%'}}>
                        <Avatar
                            sx={{
                                width: '1.5rem',
                                height: '1.5rem',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(255,255,255,0.75)'
                            }}>
                            <KeyboardArrowLeft
                                sx={{fontSize: '1.5rem', color: 'rgba(0,0,0,0.75)'}}/>
                        </Avatar>
                    </Button>
                    <Button onClick={handleNext} style={{height: '60%', marginTop: '20%'}}>
                        <Avatar
                            sx={{
                                width: '1.5rem',
                                height: '1.5rem',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(255,255,255,0.75)',
                            }}>
                            <KeyboardArrowRight
                                sx={{fontSize: '2rem', color: 'rgba(0,0,0,0.75)'}}/>
                        </Avatar>
                    </Button>
                </Grid>}
            <CardText sx={{padding: '.65rem', width: '100%', minHeight: '8.1rem'}}>
                {data?.timestamp &&
                    <span children={new Date(data.timestamp).toLocaleDateString('de-AT')}
                          style={{display: 'block', fontSize: '.7rem', color: 'grey'}}/>
                }
                {data?.caption?.split('\n\n')
                    .map((line, i) =>
                        <React.Fragment key={i}>{line}<br/></React.Fragment>)}
                <Hashtags hashtags={data?.hashtags}/>
            </CardText>
            {isCurrentTermin &&
                <CardLink children='zur Anmeldung'
                          onClick={() => contactForm.setOpen(true)}
                          sx={{cursor: 'pointer', paddingBottom: {md: '.5rem', lg: '1rem'}}}/>
            }
        </Grid>
    )
}

function InstagramDesktop({data, contactForm, hashtag}) {

    return (
        <Grid item container gap='2rem' justifyContent='center' alignItems='flex-start'
              sx={{display: {xs: 'none', md: 'flex'}, mb: '1rem', paddingTop: {xs: '2rem', md: '6rem'}}}>
            <Grid item container gap='2rem' justifyContent='center' alignItems='flex-start' width='auto'>
                {data
                    ? <>
                        <Post data={data[0]} first contactForm={contactForm} hashtag={hashtag}/>
                        <Post data={data[1]} hashtag={hashtag}/>
                    </>
                    : <><PostSkeleton/><PostSkeleton/></>}
            </Grid>
            <Grid item container gap='2rem' justifyContent='center' alignItems='flex-start' width='auto'>
                {data
                    ? <>
                        <Post data={data[2]} hashtag={hashtag}/>
                        <Post data={data[3]} hashtag={hashtag}/>
                    </>
                    : <><PostSkeleton/><PostSkeleton/></>}
            </Grid>
        </Grid>
    )
}

export {InstagramDesktop}
