import {Grid} from "@mui/material";
import * as React from "react";
import {Absatz, SourceText, Title} from "../util/Font";
import {Helmet} from "react-helmet";
import {Gallery} from "../util/Gallery";

function Vereinsleben() {
    const [text, setText] = React.useState()
    React.useEffect(() => {
        fetch(`assets/data/sub_Vereinsleben.json`)
            .then((r) => r.json())
            .then((text) => setText(text))
    }, [])

    return (
        <Grid container item maxWidth='120rem' flexDirection='column' flexGrow={1} alignItems='center' padding='1rem'
              paddingBottom='5rem'
              sx={{
                  backgroundImage: `url(assets/img/SUB-Vereinsleben.png)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% auto',
              }}>
            <Helmet>
                <title>Jung Alpenland - Vereinsleben im Jahreskreis</title>
            </Helmet>
            <Grid item maxWidth='20rem' width='70%' paddingTop='1rem'
                  component='img' src={'assets/img/logo/logo.svg'}
                  sx={{display: {xs: 'flex', md: 'none'}, zIndex: '1'}}
            />
            <Grid item maxWidth='50rem' sx={{
                marginTop: {
                    xs: '2rem',
                    md: '10rem'
                },
                backgroundColor: 'rgba(255,255,255,0.8)',
                boxShadow: '0rem 2rem 6rem 8rem rgba(255,255,255,0.8)',
            }}
                  textAlign='center'>
                <Title children={text?.title}/>
                <Gallery galleryFile='gallery_Vereinsleben.json' galleryFolder='Vereinsleben'/>
                {text?.text.split('\n').map((line, i) =>
                    <Absatz key={i} children={line}/>)}
                <SourceText children={text?.sources}/>
            </Grid>
        </Grid>
    )
}

export {Vereinsleben}
