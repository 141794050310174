import {Avatar, Grid} from "@mui/material";
import React from "react";
import {MemberName, MemberTitle} from "../../util/Font";

function Member({member}) {

    const MemberAvatar = () => (
        <Avatar src={member.img ? `assets/img/board/${member.img}` : ''}
                sx={{
                    width: {xs: '6.5rem', sm: '7rem'},
                    height: {xs: '6.5rem', sm: '7rem'},
                    border: '.2rem solid white',
                    boxShadow: '0rem 0rem 1rem .05rem lightgrey'
                }}
        />);

    return (
        <Grid container item justifyContent='center' mb='1rem'
              sx={{width: {xs: '7.8rem', sm: '10rem'}}}>
            <MemberAvatar/>
            <MemberTitle children={member.title}/>
            <MemberName children={member.name}/>
        </Grid>
    )
}

export {Member}
