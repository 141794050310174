import {Avatar, Grid, Paper} from "@mui/material";
import * as React from "react";
import {CardLink, CardText, CardTitle, Poem} from "../../util/Font";

function Aktivitaet({aktivitaet, children}) {
    if (!aktivitaet) return null

    return (
        <Grid container item flexDirection='column' alignItems='center'
              component={Paper}
              sx={{
                  maxWidth: {xs: '35rem', md: '35rem'},
                  filter: 'drop-shadow(0 0 .5rem lightgrey)',
                  mt: {xs: '8rem', sm: '10rem'}
              }}>
            <Avatar src={`assets/img/${aktivitaet.img}`}
                    alt={aktivitaet.title}
                    sx={{
                        width: {xs: '14rem', sm: '16rem'},
                        height: {xs: '14rem', sm: '16rem'},
                        border: '.4rem solid white',
                        mt: {xs: '-7rem', sm: '-8rem'}
                    }}/>
            <Grid item paddingTop='1rem' children={
                <CardTitle children={aktivitaet.title}/>}/>
            <Grid item flexGrow="1" sx={{
                paddingX: {xs: '1.5rem', lg: '2rem'},
                paddingTop: {xs: '1rem', lg: '2rem'},
                paddingBottom: {xs: '.5rem', md: '1rem'},
                textAlign: 'justify'
            }}>
                {aktivitaet?.poem &&
                    <>
                        {aktivitaet.poem.split('\n').map((line, i) =>
                            <Poem key={i} children={line} sx={{textAlign: "center"}}/>)}
                        <br/>
                    </>
                }
                {aktivitaet.text.split('\n').map((line, i) =>
                    <CardText key={i} children={line}/>)}
            </Grid>
            <Grid item sx={{paddingBottom: {xs: '1rem', md: '1.5rem'}}} children={
                <a href={aktivitaet.href} style={{textDecoration: 'none'}}>
                    <CardLink children='Mehr lesen'/>
                </a>
            }/>
            {children}
        </Grid>
    )
}

export {Aktivitaet}
