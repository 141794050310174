import * as React from "react";
import {useEffect, useState} from "react";
import {fetchMedia} from "../../../hooks/useInstagram";
import {InstagramDesktop} from "./InstagramDesktop";
import {InstagramMobile} from "./InstagramMobile";

const pattern = /#[\wÄäÖöÜü\\-ß]+/g

function Instagram({contactForm}) {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchMedia().then((response) => {
            let res = []

            response?.media?.forEach(media => {
                if (media.caption) {
                    let hashtags = media.caption.match(pattern) || []
                    let caption = media.caption.replace(pattern, '').trim()
                    res.push({...media, caption, hashtags})
                } else {
                    res.push(media)
                }
            })

            setData({highlightedHashtag: `#` + response.highlightedHashtag, media: res})
        });
    }, []);

    return (
        <>
            <InstagramDesktop data={data?.media} contactForm={contactForm} hashtag={data?.highlightedHashtag}/>
            <InstagramMobile data={data?.media} contactForm={contactForm} hashtag={data?.highlightedHashtag}/>
        </>
    );
}


export {Instagram}
