import {CardText, Poem} from "../../util/Font";
import {Grid} from "@mui/material";
import * as React from "react";

function TimelineCardText({brauchtum, sx}) {
    return (
        <Grid item sx={{
            paddingX: {xs: '1.5rem', lg: '2rem'}, textAlign: 'justify', ...sx
        }}>
            {brauchtum?.poem &&
                <>
                    {brauchtum.poem.split('\n').map((line, i) =>
                        <Poem key={i} children={line} sx={{textAlign: "center"}}/>)}
                    <br/>
                </>
            }
            {brauchtum.text.split('\n').map((line, i) =>
                <CardText key={i} children={line}/>)}
        </Grid>
    )
}

export {TimelineCardText}
